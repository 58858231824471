.table-responsive {
    overflow-x: auto;
  }
  
  .table td, .table th {
    vertical-align: middle;
  }
  
  .btn-sm {
    padding: 0.25rem 0.5rem;
  }
  
  .badge-glow {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .d-flex {
    gap: 0.5rem;
  }