.card-headerr{
    margin-left: 15px;
    display:flex;
    margin-right: 20px;
  
    
}

.card-headerr i{

    margin-right: 20px;
    
}
.card-title{
    margin-right: 88%;
    margin-left: 30px;
}