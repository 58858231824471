/* Ensure the footer is part of the content flow */
.footer {
    padding: 20px;
    background-color: #f8f9fa;
    border-top: 1px solid #e0e0e0;
    margin-top: 20px; /* Add margin to separate from the content */
}

/* Style for alerts */
.alert {
    margin-bottom: 20px;
}

.alert-success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
}

.alert-danger {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
}
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.card-title {
    margin: 0; /* Remove default margin */
}
.status-accepted {
    color: green;
}

.status-pending {
    color: red;
}