
.app-content.content {
    padding: 0 15px;
    overflow: hidden;
  }
  
 
  .marquee-text {
    color: #f88a1b;
    font-size: 1rem;
  }
  .marquee-text .ezi {
    font-weight: bold;
  }
  
 
  .card1 { box-shadow: 0px 4px 8px #eae8fd; }
  .card2 { box-shadow: 0px 4px 8px #fff1e3; }
  .card3 { box-shadow: 0px 4px 8px #fce5e6; }
  .card4 { box-shadow: 0px 4px 8px #d9f8fc; }
  .card5 { box-shadow: 0px 4px 8px #dcf6e8; }
  .card6 { box-shadow: 0px 4px 8px #fff0f8; }
  
  
  
  .dt-row-grouping { width: 100%; }
  .dt-row-grouping th, .dt-row-grouping td { padding: 10px; }
  
 
  .logo_heading h1 {
    font-weight: 700;
    font-size: 1.5rem;
  }
  .logo-marquee {
    padding: 10px 0;
  }
  .logo-img {
    height: 100px;
    margin: 0 15px;
  }
  
 

  .title-container {
    display: flex;
    justify-content: flex-start; 
  }
  
  .card-title {
    white-space: nowrap; 
  }
  

  .grid-container {
    display: grid;
    grid-template-columns: 1fr 2fr; /* Two columns: stats on the left, chart on the right */
    gap: 20px; /* Space between columns */
    align-items: start; /* Align items to the top */
  }
  
  .stats-column {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between stat items */
  }
  
  .chart-column {
    width: 100%;
  }
  
  .stat-item {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between icon and text */
  }
  
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns: 1fr; /* Stack columns on smaller screens */
    }
  
    .stats-column {
      order: 1; /* Move stats below the chart on mobile */
    }
  
    .chart-column {
      order: 1; /* Move chart above the stats on mobile */
      transform: translateX(-20px); 
      margin-left: -50px;
    }
  }