
.balloon {
  position: absolute;
  bottom: -100px;
  width: 80px;
  height: 100px;
  background-color: #ff6f61;
  border-radius: 50%;
  animation: float 6s ease-in infinite, sway 4s ease-in-out infinite;
  z-index: 1;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.balloon-shine {
  position: absolute;
  top: 15%;
  left: 20%;
  width: 30%;
  height: 20%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  border-radius: 50%;
  transform: rotate(-30deg);
}

.balloon-string {
  position: absolute;
  bottom: -50px;
  left: 50%;
  width: 2px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.3);
  transform: translateX(-50%);
  animation: stringSway 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100vh);
  }
}

@keyframes sway {
  0%, 100% {
    transform: translateX(0) rotate(-5deg);
  }
  50% {
    transform: translateX(10px) rotate(5deg);
  }
}

@keyframes stringSway {
  0%, 100% {
    transform: translateX(-50%) rotate(-5deg);
  }
  50% {
    transform: translateX(-50%) rotate(5deg);
  }
}